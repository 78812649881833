<template>
  <ul>
    <li>歌曲列表</li>
    <li v-for="(item, index) in tracks" :key="item.id">
      <i>{{ index + 1 }}</i>
      <div class="playlist" @click.stop="$emit('play', item)">
        <p>{{ item.name }}</p>
        <p>
          <span v-for="(i, index) in item.ar" :key="index">{{ i.name }}</span>
          <span> - {{ item.al.name }} </span>
        </p>
      </div>
      <i class="iconfont icon-play" @click.stop="$emit('play', item)"></i>
    </li>
  </ul>
</template>
<script>
export default {
  props: ["tracks"],
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
ul {
  li {
    display: flex;
    height: 55px;
    border-bottom: 2px dashed #d8ebe4;
    font-size: 15px;
    &:nth-of-type(1) {
      background-color: #fafafa;
      height: 40px;
      line-height: 40px;
      text-indent: 1em;
      font-size: 14px;
      font-weight: bold;
      color: #282846;
      border-radius: 50px 50px 0 0;
      border-top: 3px dashed #d8ebe4;
      border-bottom: 1px solid #d8ebe4;
    }
    i {
      width: 1%;
      display: inline-block;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-style: normal;
      color: gray;
      font-size: 18px;
      &:nth-of-type(2) {
        font-size: 30px;
        padding-right: 10px;
      }
    }
    .playlist {
      width: 98%;
      line-height: 22.5px;
      padding: 4px 0;
      p {
        color: #282846;
        overflow: hidden;
        text-overflow: ellipsis;
        text-overflow: -o-ellipsis-lastline;
        display: -webkit-box;
        -webkit-line-clamp: 1; //行数需设置
        -webkit-box-orient: vertical;
      }
      p:nth-of-type(2) {
        font-size: 13px;
        color: gray;
      }
    }
  }
}
</style>