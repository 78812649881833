<template>
  <div class="Singerpage">
    <!-- <h1>歌手详情</h1> -->
    <div class="img" :style="back"></div>
    <!-- <img :src="picUrl" alt="" /> -->
    <div class="brief">
      <p>歌手简介</p>
      <p>{{ this.briefDesc }}</p>
    </div>
    <song-lists :tracks="songs" @play="$emit('play', $event)"></song-lists>
  </div>
</template>
<script>
import SongLists from "@/components/SongLists.vue";
export default {
  props: ["id", "picUrl"],
  name: "Singerpage",
  data() {
    return {
      songs: [],
      briefDesc: "",
      back: {
        background: `url(${this.picUrl}) no-repeat center center`,
        backgroundSize: "100%",
      },
    };
  },
  components: {
    SongLists,
  },
  created() {
    console.log(this.picUrl);
    this.$axios.get(`/artist/top/song?id=${this.id}`).then((s) => {
      // this.arId.push(s.data.songs.slice(0, 3));
      this.songs = s.data.songs;
      //   console.log(s.data.songs.slice(0, 3));
      //   console.log(s);
    });
    this.$axios.get(`/artist/desc?id=${this.id}`).then((d) => {
      this.briefDesc = d.data.briefDesc;
      console.log(this.briefDesc);
      console.log(d);
    });
  },
};
</script>
<style lang="less" scoped>
.Singerpage {
  .img {
    display: flex;
    width: 100%;
    height: 80vw;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 10px;
    &::after {
      content: "";
      background: rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 100%;
      left: 0;
    }
  }
  .brief {
    padding: 0 10px;
    p {
      font-size: 18px;
      color: #333;
      font-weight: bold;
      padding-top: 10px;
      &:last-of-type {
        margin-bottom: 10px;
        height: 90px;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        text-overflow: -o-ellipsis-lastline;
        display: -webkit-box;
        -webkit-line-clamp: 4; //行数需设置
        -webkit-box-orient: vertical;
        font-size: 14px;
        color: #818181;
      }
    }
  }
}
</style>